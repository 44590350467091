import React from 'react'
import { Layout, Stack, Main, Sidebar } from '@layout'
import { Card, Text } from 'theme-ui'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Section from '@components/Section'
import { Link } from 'gatsby'
import Seo from '@widgets/Seo'
import ContactForm from '@widgets/ContactForm'
//import ContactInfo from '@widgets/ContactInfo'
//import Commitment from '@widgets/Commitment'

const styles = {
  imageWrapper: {
    borderRadius: `default`,
    overflow: `hidden`,
    position: `relative`
  },
  button: {
    display: [`none`, `block`],
    position: `absolute`,
    bottom: 4,
    right: 4
  },
  grid: {
    flexWrap: [`wrap`, `wrap`, `nowrap`],
    ' > div + div': {
      ml: [0, 0, 5]
    }
  },
  column: {
    flex: `auto`,
    flexBasis: [`full`, `full`, `1/2`]
  },
  link: {
    color: '#5566E5',
    textDecoration: 'none',
  }
}

const PageContact = props => (
  <Layout {...props}>
    <Seo title='Contact' />
    <Divider />
    <Stack>
      <Main>
        <PageTitle
          header="Let's Connect"
          subheader='Comments - Suggestions - Recommendations - Inquires - Help'
        />
        <Divider />
        {/* <ContactForm /> */}
        <Section title='How to Connect'>
                Join the swift-arm slack team &nbsp;-&nbsp; 
                <Link
                    variant='mute'
                    target='_blank'
                    title='Build Swift on Arm'
                    href='https://launchpass.com/swift-arm'
                    rel='noopener'
                    style={styles.link}
                  >
                     swift-arm.slack.com
                  </Link>
                  <br/>
                  Use the Swift Forums &nbsp;-&nbsp; 
                <Link
                    variant='mute'
                    target='_self'
                    title='Forums'
                    href='https://forums.swift.org/'
                    rel='noopener'
                    style={styles.link}
                  >
                     forums.swift.org
                  </Link>
              </Section>
      </Main>
    </Stack>
  </Layout>
)

export default PageContact
